$primary: #094151;
$control-grey: #DFE6E8;
$theme-colors: ( "primary": $primary, "control-grey": $control-grey, "warning": #ffc107, "light-grey": #f6f6f6);
$border-radius: 6px;
// $utilities: (
//     "rounded": (
//         property: border-radius,
//         class: rounded,
//         values: (
//             4: 10px,
//         )
//     ),
// );
@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;0,600;1,700&display=swap');
html {
    font-size: 0.8rem;
}

body {
    font-family: 'Work Sans', sans-serif;
    background: #eceff0;
    color: $primary;
    padding-bottom: 2em;
}

.card {
    color: black
}

.header {
    background: #F8F8F8
}

.offcanvas-backdrop.show {
    opacity: 0 !important;
}

.offcanvas-top {
    border-bottom: none !important;
    height: 230px;
}

.headerMenu {
    transition: transform 0.25s ease-in-out;
}

.header-unpin {
    display: none;
    transform: translateY(-65px);
}

.header-pin {
    display: block;
    transform: translateY(0);
}

.mobile_menu_links {
    text-align: left;
    padding: 6em 1em 1em;
}

.mobile_menu_links button,
.mobile_menu_social button {
    color: $primary;
    display: block;
    font-size: 15px !important;
    font-weight: 600;
}

.mobile_menu_social {
    border-top: 1px solid #ccc;
    padding: 1em 1em 2em;
}

.mobile_menu_social button {
    display: inline-block;
}

.dropdown-menu {
    height: 200px;
    overflow-y: scroll;
    z-index: 2000 !important;
}

.datePicker1 .DateInput {
    display: none !important;
}

.SingleDatePicker_picker {
    position: unset !important;
}

.SingleDatePickerInput__withBorder {
    border: none !important;
}

.DayPicker__withBorder {
    box-shadow: unset !important;
}

.DateRangePicker_picker {
    z-index: 9000 !important;
}

.DateRangePicker_1 .DayPicker_transitionContainer {
    border: 1px solid #666;
}

.DateRangePickerInput_1 {
    background-color: $control-grey !important;
    border: none !important;
    height: 30px !important;
    border-radius: 6px;
}

.DateInput_input {
    font-weight: bold !important;
}

.DateInput_input__small {
    font-size: 1rem !important;
}

.DateRangePickerInput__withBorder {
    border-radius: 6px !important;
}

.DateInput_input,
.DateInput {
    background: transparent !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid $primary !important;
}

.CalendarDay__selected_span {
    background: $primary !important;
    border: 1px solid $primary !important;
}

.CalendarDay__selected {
    background: $primary !important;
    border: 1px solid $primary !important;
    color: white;
}

h1 {
    font-weight: 600
}

.navlinks button {
    color: $primary;
    font-weight: bold;
    font-size: 14px;
    margin-top: 0.5em;
    padding: 0.6em 1em;
    &:hover {
        background: rgba(9, 65, 81, 0.1);
        color: $primary;
    }
}

.country-select .dropdown-toggle::after {
    margin-left: 3em;
}

.control-grey {
    background: $control-grey;
}

.timeline-date-select button {
    width: 100%;
}

.tooltip-inner {
    background-color: $primary;
    opacity: 1 !important;
    font-size: 1.2em;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: $primary !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: $primary !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $primary !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: $primary !important;
}

.tooltip-inner {
    white-space: pre-line;
}

.badge-data-alert {
    height: 25px !important;
    width: 25px !important;
    border-radius: 6px !important;
}

.badge-data-alert svg {
    position: relative;
    top: 5px;
}

.badge-data-alert path {
    fill: $primary
}

.badge-inc-dec,
.badge-ince-dec span {
    color: #000 !important;
}

.chart-scale {
    width: 10px !important;
    height: 20px !important;
    margin-right: 6px !important;
    display: inline-block;
    border-radius: 4px;
}

.chart-gradient-container {
    position: relative;
}

.chart-gradient-container .chart-amount {
    position: absolute;
    left: 20px;
    top: 0em;
    white-space: nowrap;
}

.chart-gradient-container .chart-amount:last-child {
    top: unset;
    bottom: 0;
}

.chart-gradient {
    border-radius: 5px;
    width: 10px;
    height: 80px;
    background: rgb(255,238,238);
    background: linear-gradient(0deg, rgba(255,238,238,1) 0%, rgba(255,84,84,1) 100%);
}

.noUi-target {
    background: $control-grey !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}

.noUi-horizontal {
    height: 100% !important;
}

.noUi-horizontal .noUi-handle {
    min-height: 25px;
    height: 100% !important;
    top: 3px !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background: $primary !important;
    cursor: pointer !important;
}

.noUi-handle:before,
.noUi-handle:after {
    height: 10px !important;
}

.noUi-pips-horizontal {
    top: 0 !important;
}

.noUi-value {
    display: none;
}

.noUi-marker:first-child,
.noUi-marker:nth-last-child(2) {
    display: none !important;
}

.leaflet-container {
    width: 100%;
    height: 530px;
}

hr {
    opacity: 0.1 !important;
}

.form-select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-size: unset !important;
}

.cursor-pointer {
    cursor: pointer;
}

.map-legend .scale-none {
    background-color: #999 !important;
}

.accordion-item {
    border: none !important;
}


/* Data Table */

.rdt_TableRow,
.rdt_TableHeadRow {
    position: relative;
}

.rdt_TableHeadRow {
    z-index: 14;
}

.rdt_TableCell:first-child {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
}

.rdt_TableHead {
    z-index: 100 !important;
}

.rdt_TableCol:first-child {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
}

.rdt_TableRow:nth-child(odd) .rdt_TableCell:first-child {
    background: #FAFAFA;
}

.rdt_TableRow:nth-child(even) .rdt_TableCell:first-child {
    background: #FFF;
}


/* Data Table Dropdowns */

.rmsc {
    border-radius: 6px !important;
    height: 30px;
    position: relative;
    z-index: 999;
}

.rmsc .dropdown-container {
    background-color: $control-grey !important;
    border: none !important;
    height: 30px;
}

.rmsc .dropdown-container:focus-within {
    border-color: #84a0a8 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(9 65 81 / 25%) !important;
}

.rmsc .dropdown-heading {
    height: 30px !important;
}

.form-select {
    background-color: $control-grey !important;
    border: none !important;
    height: 30px;
    border-radius: 6px;
}


/* Chart */

.custom-tooltip {
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 0.5em;
    border-radius: 5px;
}